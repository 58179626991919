import { createTheme, Theme } from '@danone-global/internal/react/core'

import { fonts } from './theme.fonts'

export const style = {
  colors: {
    green: '#00AB1D',
    lightGray: '#ECECEC',
  },

  border: {
    default: '1px solid #DDD',
    radius: '12px',
    color: '#DDD',
  },

  boxShadow: {
    inner: 'inset 2px 2px 3px 0 rgba(0,0,0,0.1)',
  },
}

const theme: Theme = createTheme({
  direction: 'ltr',
  unit: 'px',

  typography: {
    fontFamily: 'cg-font',
    fonts,
  },

  palette: {
    text: {
      primary: '#4c4c4c',
      secondary: '#fff',
      disabled: '#fff',
      heading: '#4c4c4c',
    },

    primary: {
      light: '#faeae9',
      main: '#d32e28',
      dark: '#d32e28',
    },

    secondary: {
      light: '',
      main: '#d32e28',
      dark: '#d32e28',
    },

    warning: {
      light: '#F8EAEC',
      main: '#E03247',
      dark: '',
    },
  },

  breakpoints: {
    values: {
      sm: 414,
      md: 960,
      lg: 976,
    },
  },
})

theme.overrides = {
  QuickView: {
    root: {
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 32px)',
        minWidth: 'unset',
      },
    },

    title: {
      color: style.colors.green,
    },

    addedIcon: {
      backgroundColor: style.colors.green,
    },

    closeIcon: {
      fill: theme.palette.text.primary,
    },
  },

  ShoppingCart: {
    root: {
      '& svg': {
        display: 'inherit',
      },
    },
  },

  Price: {
    root: {
      fontWeight: 'bold',
    },

    free: {
      color: `${style.colors.green} !important`,
    },
  },

  CartContent: {
    bottomButtons: {
      '& a': {
        display: 'flex',
        justifyContent: 'center',
      },
    },

    [theme.breakpoints.down('md')]: {
      '& a:not(:last-child)': {
        marginBottom: 20,
      },
    },

    relatedProducts: {
      '& > div': {
        [theme.breakpoints.down('sm')]: {
          border: style.border.default,
        },
      },
    },
  },

  RelatedProducts: {
    hr: {
      borderTopColor: style.border.color,
    },

    'quick-view': {
      '& > p': {
        // color: style.colors.text.primary,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },

  RelatedProduct: {
    default: {
      border: style.border.default,

      [theme.breakpoints.down('sm')]: {
        border: 'none',
        padding: 0,
      },
    },

    container: {
      '& > div > div > div': {
        alignSelf: 'flex-end',
      },
    },

    imageContainer: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: 20,
        minHeight: 100,

        '& > img': {
          width: '100% !important',
          height: '100% !important',
          maxWidth: '100%',
          maxHeight: '100%',
        },
      },

      '$quick-view &': {
        width: 64,
        height: 64,
        minHeight: 64,
        maxHeight: 64,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 0,
        marginRight: 15,
      },
    },

    subContainer: {
      marginTop: 10,

      '& button': {
        width: 64,
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
          width: 56,
        },
      },

      '& > div:first-of-type': {
        minWidth: 80,
      },
    },
  },

  MyOrder: {
    root: {
      backgroundColor: style.colors.lightGray,

      '& p, & div > span': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
      },
    },
  },

  AddDiscountCode: {
    addButton: {
      '& span': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },

    input: {
      padding: 5,
      boxShadow: style.boxShadow.inner,
    },

    button: {
      padding: '0 12px',
    },
  },

  DiscountCodePrice: {
    unmatchedDiscountText: {
      color: '#d32e28',
    },
  },

  LineItems: {
    root: {
      borderRadius: style.border.radius,
      border: style.border.default,
    },
  },

  LineItem: {
    nameContainer: {
      '& p': {
        color: theme.palette.text.primary,
      },
    },

    viewItemLink: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },

    removeContainer: {
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },

    label: {
      backgroundColor: theme.palette.primary.main,

      '& p': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.white,
      },
    },

    errorNotification: {},

    imageContainer: {
      flex: '0 0 auto',
      width: 80,
      maxHeight: 80,

      '& img': {
        width: '100% !important',
        height: 'auto !important',
        maxWidth: '100%',
        maxHeight: '100%',
      },

      '$quick-view &, $checkout &': {
        width: 64,
        height: 64,
        minHeight: 64,
        maxHeight: 64,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 0,
        marginRight: 15,
      },
    },

    free: {
      color: `${style.colors.green} !important`,
      fontWeight: 'bold',
    },
  },

  SubscriptionSelector: {
    root: {
      border: style.border.default,
    },

    option: {
      color: theme.palette.primary.main,
    },
  },

  Checkout: {
    icon: { fill: style.colors.green },

    securePayment: {
      color: style.colors.green,
    },
  },

  CheckoutOrder: {
    root: {
      backgroundColor: style.colors.lightGray,

      [theme.breakpoints.down('sm')]: {
        padding: 24,
      },

      '& div > p, & div > span': {
        color: theme.palette.text.primary,
      },
    },
  },

  CheckoutSteps: {
    actions: {
      marginTop: 23,

      [theme.breakpoints.down('sm')]: {
        '& button': {
          width: '100%',
          justifyContent: 'center',
        },
      },
    },
  },

  StepContainer: {
    root: {
      border: style.border.default,

      '& h4': {
        color: theme.palette.text.primary,
      },

      '&:not($completeContainer):not($currentStep) h4': {
        opacity: 0.5,
      },

      '&$completeContainer > $titleContainer:only-child': { marginBottom: 0 },
    },

    stepCompleteIcon: {
      backgroundColor: style.colors.green,
      width: 24,
      height: 24,
    },

    completeContainer: {
      '& .billing-icon': {
        display: 'inline-block !important',
        fill: style.colors.green,
        marginRight: 10,
      },
    },
  },

  FieldsRadio: {
    root: {
      '& label': {
        fontSize: 16,
      },
    },

    icon: {
      backgroundColor: '#F4F4F4',
      borderColor: style.border.color,
    },

    option: {
      padding: 14,
    },

    active: {
      '& $icon': {
        borderColor: style.border.color,
      },
    },
  },

  FieldsInput: {
    input: {
      boxShadow: style.boxShadow.inner,
      height: 48,
    },
  },

  FieldsAutoComplete: {
    input: {
      boxShadow: style.boxShadow.inner,
      height: 48,
    },
  },

  FieldsSelect: {
    input: {
      backgroundColor: '#ECECEC',
      color: '#4C4C4C',
      borderRadius: 6,
      border: 'none',
      minHeight: 48,
    },
    inputWrap: {
      backgroundColor: '#ECECEC',
      borderRadius: 6,
      paddingRight: 6,
      borderColor: '#DDDDDD',
    },
  },

  DeliveryMethod: {
    icon: {
      backgroundColor: '#F4F4F4',
      borderColor: style.border.color,
    },

    active: {
      '& $icon': {
        borderColor: style.border.color,
      },
    },
  },

  AdyenDropIn: {
    root: {
      ['& .adyen-checkout__payment-method, label > span, ' +
      '.adyen-checkout__payment-method__header, ' +
      '.adyen-checkout__payment-method__name--selected, ']: {
        // color: theme.utils.createStyle(style.colors.text.primary, '!important'),
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
      },

      '& .adyen-checkout__payment-method__radio': {
        backgroundColor: '#F4F4F4 !important',
        borderColor: '#DDD  !important',
      },
    },
  },

  AddToCart: {
    notifyMeOutOfStock: {
      color: '#4c4c4c',
    },
  },

  NotifyMeModal: {
    notification: {
      background: '#E8F5E9',
    },
    checkIcon: {
      background: '#66B800',
    },
  },

  WidgetReviews: {
    sorting: {
      '& .reviews-sorting': {
        '&__control': {
          borderRadius: '6px',
          backgroundColor: '#ECECEC',
          borderColor: '#DDDDDD',
        },

        '&__placeholder, &__single-value': {
          fontWeight: 300,
        },

        '&__option': {
          fontWeight: 300,
        },
      },
    },
  },

  Review: {
    action: {
      backgroundColor: '#ECECEC',
      color: '#4C4C4C',
      fontWeight: 300,

      '&:hover': {
        backgroundColor: '#ECECEC',
        color: '#4C4C4C',
      },
    },
    careLineContent: {
      backgroundColor: '#ECECEC',
    },
  },

  CreateReview: {
    productWrap: {
      backgroundColor: '#ECECEC',
    },
    productName: {
      color: '#4C4C4C',
    },
    commentField: {
      '& label span span:last-child': {
        fontWeight: 300,
      },
    },
  },

  Button: {
    link: {
      '&:disabled, &:disabled:hover': {
        color: theme.palette.text.primary,
        opacity: 0.5,
      },
    },
  },
}

export { theme }
