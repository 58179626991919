import { Config } from '@danone-global/internal/react/core'

import { baseEnvironment } from './environment.base'

export const environment: Config = {
  ...baseEnvironment,

  isProduction: true,

  isDevelopment: false,

  ctProjectKey: 'hk-prd-danone',

  ctClientId: 'B6mAkxQMNrXgCX0fFbTdNerQ',

  ctClientSecret: 'vxLpFwbO_tPycZcoa4TViHwUkY-gpUCE',

  defaultChannelId: '0e3738a3-5ffc-4455-aa22-c8e5011a74c2',

  sentryDSN:
    'https://26e95ebef18de217c29264c5f256280b@o4506427613577216.ingest.sentry.io/4506433323008000',

  channelKeyToId: {
    zorgen_hk: '0e3738a3-5ffc-4455-aa22-c8e5011a74c2',
    zorgen_cn: 'a303fef9-5ae4-41fc-9e34-03ff29e97f0d',
    zorgen: 'a803d3d2-33f2-47b7-940d-24cbe27654d2',
    afl: 'e3f12ef6-43d0-4886-8f9d-0541f8b0ddf0',
  },

  adyen: {
    environment: 'live',
    clientKey: 'live_AUWAD6NVGRHOLNJ3AKADZUNIY4I3WXVE',
  },
}
