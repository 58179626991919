import { DeepPartial, Theme } from '@danone-global/internal/react/core'
import { theme as cgTheme } from '@danone-global/internal/react/themes/cowandgate'
import deepMerge from 'deepmerge'

// Here HK can add changes on the Cow and Cate theme

const themeOverrides: DeepPartial<Theme> = {
  palette: {},
  overrides: {},
}

const theme = deepMerge(cgTheme, themeOverrides)

export { theme }
